import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import moment from 'moment'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionService from '@services/fetch_stats_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaigns',
  attributes: [
    'id',
    'event_id',
    'partner_id',
    'category',
    'name',
    'description',
    'limit_shares_count',
    'started_at',
    'ended_at',
    'is_enabled',
    'registration_started_at',
    'registration_ended_at',
    'is_registration_enabled',
    'review_started_at',
    'review_ended_at',
    'sample_will_arrive_started_at',
    'sample_will_arrive_ended_at',
    'sample_exp_started_at',
    'sample_exp_ended_at',
    'cover',
    'code',
    'slogan',
    'slogan_link_url',
    'first_brand',
    'kpi',
    'required_media_accounts',
    'is_registrable',
    'stats_config',
    'intro',
    'intro_type',
    'intro_items',
    'has_periods',
    'has_sample',
    'profit_calculate_method',
    'profit_calculate_config',
    'registered_or_joined_count',
    'top_event_product_ship_ids',
    'images',
    'event_info',
    'periods',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'event_id',
    'partner_id',
    'category',
    'name',
    'description',
    'limit_shares_count',
    'started_at',
    'ended_at',
    'slogan',
    'slogan_link_url',
    'registration_started_at',
    'registration_ended_at',
    'review_started_at',
    'review_ended_at',
    'sample_will_arrive_started_at',
    'sample_will_arrive_ended_at',
    'sample_exp_started_at',
    'sample_exp_ended_at',
    'kpi',
    'required_media_accounts',
    'share_ids',
    'product_ids',
    'intro',
    'profit_calculate_method',
    'profit_calculate_config',
    'event_info_fee',
    'event_info_rate_type',
    'event_info_address',
    'uploaded_attachment_ids'
  ]
}

export default class PromoterCampaign extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static categories = { official: 1, 'kol-select': 2, main: 3 }
  static profitCalculateMethods = {
    by_profit_ratio: 0,
    by_profit_price: 1,
    by_promoter_level: 2,
    by_campaign_level: 3
  }

  registerText() {
    if (this.has_periods)
      return this.category === 'event' ? '選擇活動時段' : '選擇帶貨時段'

    return '報名'
  }

  defaultTimeFormat() {
    return this.category === 'event' ? 'MM/DD(dd) HH:mm' : 'MM/DD (ddd)'
  }

  progress() {
    const currentTime = moment().unix()

    if (currentTime < this.registration_started_at) return 'not_open'
    if (currentTime < this.registration_ended_at) return 'registration'
    if (currentTime < this.started_at) return 'will_open'
    if (currentTime < this.ended_at) return 'for_sale'
    return 'ending'
  }

  isStarted() {
    if (!this.started_at) return true // 沒有設定，預設直接開始

    return moment().unix() > this.started_at
  }

  isExpired() {
    if (!this.ended_at) return false

    return moment().unix() > this.ended_at
  }

  isOpen() {
    return this.is_enabled && moment().unix() < this.ended_at
  }

  isForSale() {
    return (
      this.is_enabled &&
      moment().unix() > this.started_at &&
      moment().unix() < this.ended_at
    )
  }

  isRegistrationStarted() {
    if (!this.registration_started_at) return false

    return moment().unix() > this.registration_started_at
  }

  isRegistrationExpired() {
    if (!this.registration_ended_at) return false

    return moment().unix() > this.registration_ended_at
  }

  isOnRegistration() {
    if (!this.is_registrable) return false
    if (!this.registration_started_at) return false
    if (!this.registration_ended_at) return false
    if (!this.isOpen()) return false

    const currentTime = moment()
    return (
      this.is_registration_enabled &&
      currentTime.unix() > this.registration_started_at &&
      currentTime.unix() < this.registration_ended_at
    )
  }

  isRegistrationFull() {
    if (!this.limit_shares_count) return false

    return this.registered_or_joined_count >= this.limit_shares_count
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  static fetchModelStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/model_stats?${queryString}`)
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  updateSampleVariant(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/sample_variant`, {
      data: {
        type: 'update-sample-variant',
        attributes: form
      }
    })
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  toggleRegistrationEnabled() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_registration_enabled`
    )
  }

  toggleHasPeriods() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_has_periods`)
  }

  toggleHasSample() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_has_sample`)
  }

  updateTopEventProductShipIds(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/top_event_product_ship_ids`,
      {
        data: {
          type: 'update-promoter-campaign-top-event-product-ship-ids',
          attributes: form
        }
      }
    )
  }

  updateStatsConfig(statsConfig) {
    return axios.put(`${this.apiBasePath()}/${this.id}/stats_config`, {
      data: {
        type: 'update-promoter-campaign-stats-config',
        attributes: {
          stats_config: statsConfig
        }
      }
    })
  }

  // eventProductShips handlers
  fetchEventProductShips(options = {}) {
    const query = [
      FetchingDataOptionsService.call(options),
      options.top_product ? '&top_product=1' : null
    ]
      .filter(Boolean)
      .join('')

    return axios.get(
      `${this.apiBasePath()}/${this.id}/event_product_ships?${query}`
    )
  }

  batchSaveEventProductShips(productShips) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-campaign-prodcut-ships',
        attributes: {
          product_ships: productShips
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/event_product_ships/batch_save`,
      requestBody
    )
  }

  // product_categories
  fetchProductCategories(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/product_categories?${FetchingDataOptionsService.call(options)}`
    )
  }

  // campaign_share_ship
  fetchCampaignShareShips(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/campaign_share_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  batchSaveCampaignShareShips({ promoterShareIds }) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-campaign-share-ships',
        attributes: {
          share_ids: promoterShareIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/campaign_share_ships/batch_save`,
      requestBody
    )
  }

  // campaign images
  batchCreateCampaignImages({ form }) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/images/batch_create`,
      form
    )
  }

  destroyCampaignImage(id) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/images/${id}`)
  }

  // slides
  fetchSlides(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/slides?${FetchingDataOptionsService.call(options)}`
    )
  }

  saveSlide(slide, formData) {
    if (slide.isNewRecord()) {
      return axios.post(`${this.apiBasePath()}/${this.id}/slides`, formData)
    }
    return axios.put(
      `${this.apiBasePath()}/${this.id}/slides/${slide.id}`,
      formData
    )
  }

  destroySlide(slide) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/slides/${slide.id}`)
  }

  // support documents
  uploadSupportDocument(formData) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/support_documents`,
      formData
    )
  }

  // campaign_main_groups
  fetchCampaignMainGroups(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/campaign_main_groups?${FetchingDataOptionsService.call(options)}`
    )
  }

  saveCampaignMainGroup(campaignMainGroup) {
    const requestBody = {
      data: {
        type: 'save-promoter-campaign-main-group',
        attributes: campaignMainGroup
      }
    }

    if (campaignMainGroup.isNewRecord) {
      return axios.post(
        `${this.apiBasePath()}/${this.id}/campaign_main_groups`,
        requestBody
      )
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/campaign_main_groups/${
        campaignMainGroup.id
      }`,
      requestBody
    )
  }

  destroyCampaignMainGroup(campaignMainGroup) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/campaign_main_groups/${
        campaignMainGroup.id
      }`
    )
  }

  // periods
  fetchPeriods(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/periods?${FetchingDataOptionsService.call(options)}`
    )
  }

  savePeriod(period) {
    const requestBody = {
      data: {
        type: 'save-promoter-period',
        attributes: period
      }
    }

    if (period.isNewRecord()) {
      return axios.post(`${this.apiBasePath()}/${this.id}/periods`, requestBody)
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/periods/${period.id}`,
      requestBody
    )
  }

  destroyPeriod(id) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/periods/${id}`)
  }

  static createMainCampaign(form) {
    return axios.post(`${new this().apiBasePath()}/main_campaign`, {
      data: {
        type: 'create-main-campaign',
        attributes: form
      }
    })
  }

  static createBuyoutCampaign(form) {
    return axios.post(`${new this().apiBasePath()}/buyout_campaign`, {
      data: {
        type: 'create-buyout-campaign',
        attributes: form
      }
    })
  }

  static createEventCampaign(formData) {
    return axios.post(`${new this().apiBasePath()}/event_campaign`, formData)
  }

  static getOfficialCampaign() {
    return axios.get(`${new this().apiBasePath()}/offical_campaign`)
  }

  // promotion_selections
  fetchPromotionSelections(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promotion_selections?${FetchingDataOptionsService.call(options)}`
    )
  }

  updateProfitCalculateMethod(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profit_calculate_method`,
      {
        data: {
          type: 'update-calculate-method',
          attributes: form
        }
      }
    )
  }

  updateIntro(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/intro`, {
      data: {
        type: 'update-intro',
        attributes: form
      }
    })
  }

  linkUrl(userScope) {
    switch (userScope) {
      case 'Admin':
        return `/admin/kolcenter/campaigns/${this.id}`
      case 'Partner':
        return `/partner/promoter_campaigns/${this.id}`
      case 'PromoterMember':
        if (this.category === 'buyout') {
          return `/kolcenter/buyout_campaigns/${this.code}`
        } else if (this.category === 'event') {
          return `/kolcenter/event_campaigns/${this.code}`
        } else {
          return `/kolcenter/official_campaigns/${this.code}`
        }
    }
  }

  bulkModifySampleShipmentState(formData) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bulk_modify_sample_shipment_state`,
      formData
    )
  }

  static newLinkUrl(userScope, category) {
    switch (userScope) {
      case 'Admin':
        if (category === 'buyout')
          return `/admin/kolcenter/campaigns/new?category=buyout`

        if (category === 'event')
          return `/admin/kolcenter/campaigns/new?category=event`

        return `/admin/kolcenter/campaigns/new`
      case 'Partner':
        return `/partner/promoter_campaigns/new`
    }
  }

  getPromotionRules(vueStore) {
    const allRules = vueStore.getters['promotions/allRules']

    return allRules.filter((rule) => {
      if (rule.rule !== 'promoter_campaign') return false

      return rule.preferences.promoter_campaigns.find((c) => {
        return parseInt(c.id) === parseInt(this.id)
      })
    })
  }

  getPromotions(vueStore) {
    const rules = this.getPromotionRules(vueStore)

    return vueStore.getters['promotions/all'].filter((promotion) => {
      return !!rules.find((rule) => {
        return parseInt(rule.promotion.id) === parseInt(promotion.id)
      })
    })
  }

  getPromotionActions(vueStore) {
    const promotions = this.getPromotions(vueStore)

    const allActions = vueStore.getters['promotions/allActions']

    return allActions.filter((action) => {
      return !!promotions.find((promotion) => {
        return parseInt(action.promotion.id) === parseInt(promotion.id)
      })
    })
  }
}
